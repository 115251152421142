<template>
  <div class="row m-0 home p-1">
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
      <div class="container p-0">
      <div class="mx-1 my-2 box-new-top" v-if="isPageActive('hotgame') || !isMember" >
        <b-carousel
          id="carousel-1"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1082"
          img-height="338"
          class="lightSweep"
          style="text-shadow: 1px 1px 2px #333;border-radius: 20px !important;"
        >
        <template v-if="(getAgentNews.banner === undefined || getAgentNews.banner === null || (typeof this.getAgentNews.banner !== 'object'))">
          <template v-if="AGENT_S_BASE=='UFO711'">
            <b-carousel-slide
            :img-src="require('@/assets/images/H3_ufo711.webp')">
            </b-carousel-slide>
          </template>
          <template v-else-if="AGENT_S_BASE=='SLOTPG'">
            <b-carousel-slide
            :img-src="require('@/assets/images/banner_I12.webp')">
            </b-carousel-slide>
            <b-carousel-slide
            :img-src="require('@/assets/images/banner_I13.webp')">
            </b-carousel-slide>
          </template>
          <template v-else-if="AGENT_S_BASE=='GIANT999'">
            <b-carousel-slide
            :img-src="require('@/assets/images/banner3.png')">
            </b-carousel-slide>
          </template>
          <template v-else-if="AG_USER=='ag_tgone_bet'">
            <b-carousel-slide
            :img-src="require('@/assets/images/tgonebet_banner.jpeg')">
            </b-carousel-slide>
          </template>
          <template v-else-if="AGENT_S_BASE=='TGONE'">
            <b-carousel-slide
            :img-src="require('@/assets/images/TGONEbanner.png')">
            </b-carousel-slide>
          </template>
          <template v-else-if="AGENT_S_BASE=='WISDOMBET'">
            <b-carousel-slide
            :img-src="require('@/assets/images/banner_wisdom.png')">
            </b-carousel-slide>
          </template>
          <template v-else-if="AGENT_S_BASE=='PYN'">
            <b-carousel-slide
            :img-src="require('@/assets/images/new_pyn2.webp')">
            </b-carousel-slide>
            <b-carousel-slide
            :img-src="require('@/assets/images/newpyn 4.webp')">
            </b-carousel-slide>
          </template>
          <template v-else-if="AGENT_S_BASE=='NV24'">
            <b-carousel-slide
            :img-src="require('@/assets/images/banner_nv1.webp')">
            </b-carousel-slide>
            <b-carousel-slide
            :img-src="require('@/assets/images/banner_nv2.webp')">
            </b-carousel-slide>
          </template>
          <template  v-else>
            <b-carousel-slide
              :img-src="require('@/assets/images/banner2.jpg')">
            </b-carousel-slide>
        </template>
        </template>
        <template v-else>
          <b-carousel-slide
          v-for="(item,id) in getAgentNews.banner" :key="id"
          :img-src="item"
          >
          </b-carousel-slide>
        </template>
        </b-carousel>
    </div>
  </div>
    <!-- credit secsion -->
    <div class="container p-0">
      <div class="row mt-2 px-1 m-0" v-if="isPageActive('hotgame') && isMember" >
        <div class="col-4 pl-1 pr-1 re-balance playnow cursor_pointer" @click="pushRoute('/member/request/all')">
            <div class="px-2 box-member-money-left">
                <div class="text-white"><i class="fas fa-wallet text-white "></i> ยอดเครดิต
                </div>
                <div class="text-white text-balance font-1-8 line-height-0-8 mb-0 playnow">{{ this.$store.getters.getBalance }}</div>
            </div>
        </div>
        <div class="col-4 pl-1 pr-0">
            <div class="px-2 text-left box-member-money-right cursor_pointer" @click="pushRoute('/member/affiliate/revenue')">
                <div class="row">
                  <div class="col-9 text-left text-white ">
                    <i class="fas fa-comment-dollar" style="color: #f0db78;"></i> รายได้
                    <div class="text-white">
                        {{ getCurrentIncome ? getCurrentIncome:0 }}
                    </div>
                  </div>
                  <router-link to="/member/vip" v-if="getIsVip">
                  <div class="col-3 text-center icon-vip p-0" >
                    <i class="fas fa-crown icon-vip"></i>
                     <div class="" style="font-size: 1.5rem;top: -5px;left:2px;position: relative;line-height: 1;word-spacing: 20px;text-shadow: 0 0 5px #f7da74;">
                        {{ this.$store.getters.getVip }}
                    </div>
                  </div>
                  </router-link>
                </div>
            </div>
        </div>
        <div class="col-4 pl-1 pr-0">
            <div class="px-2 text-left box-member-gem-right">
                <div class="row">
                  <div class="col-9 text-left text-white cursor_pointer" @click="pushRoute('/member/gem')">
                    <i class="fas fa-gem"></i> เพชร
                    <div class="text-white">
                        {{ gems }}
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="row mt-2 px-1 m-0" v-if="isPageActive('hotgame') && isMember" >
        <div class="col-3 pl-1 pr-1">
        <router-link
            :to="hasBank ? '/member/deposit' : '/member/settings/bank/add'" v-bind:class="isDisabled()" class="playnow">
                <div class="text-white text-center line-height-1-3 box-deposit lightSweep">
                    <img src="@/assets/images/ic_deposit.png" height="20px"><br>
                    <div class="text-white font-1-2 mb-0 playnow">{{ $t('deposit') }}</div>
                </div>
            </router-link>
        </div>
        <div class="col-3 px-1">
        <router-link
            :to="'/member/withdraw'" v-bind:class="isDisabled()" class="playnow">
                <div class="text-white text-center line-height-1-3 box-withdraw" >
                    <img src="@/assets/images/ic_withdraw.png" height="20px"><br>
                    <div class="text-white font-1-2 mb-0 playnow">{{ $t('withdraw') }}</div>
                </div>
              </router-link>
        </div>
        <div class="col-6 pl-1 pr-0">
         <router-link
            :to="'/member/affiliate'">
                <div class="text-white text-center line-height-1-3 box-affiliate" >
                    <img src="@/assets/images/ic_linkaff.png" height="20px"><br>
                    <div class="text-black font-1-2 mb-0 playnow">แนะนำเพื่อน</div>
                </div>
                </router-link>
        </div>
      </div>
    </div>
        <!-- end credit secsion -->
      <div class="container p-0">
        <app-menu-sidebar-mobile />
        <app-header v-if="!isMember" />
        <!-- <app-menu-sidebar-mobile v-if="!(isMember && AGENT_S_BASE == 'PYN' && isPageActive('hotgame'))" />
        <app-header v-if="!isMember && AGENT_S_BASE != 'PYN'" /> -->
        <!-- <template  v-if="isPageActive('hotgame')">
          <router-link :to="'/casino'" >
            <img src="@/assets/images/pyn/banner_casino.png" class="w-100 cursor-pointer p-1 pt-2 br-radius" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" >
          </router-link>
          <router-link :to="'/lotto'">
          <img src="@/assets/images/pyn/banner_huay.gif" class="w-100 cursor-pointer p-1 br-radius" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" >
        </router-link>
          <router-link :to="'/slot'">
          <img src="@/assets/images/pyn/banner_slot.png" class="w-100 cursor-pointer p-1 br-radius" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" >
        </router-link>
          <router-link :to="'/sport'">
          <img src="@/assets/images/pyn/banner_sport.png" class="w-100 cursor-pointer p-1 br-radius" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" >
        </router-link>
        </template> -->
        <app-casino v-if="isPageActive('casino')"/>
        <app-visual-sport v-if="isPageActive('visual_sport')"/>
        <app-hotgame v-if="isPageActive('hotgame')" />
        <app-slot v-if="isPageActive('slot')"/>
        <app-promotions v-if="isPageActive('promotions')"/>
        <app-sport v-if="isPageActive('sport')"/>
        <app-fishing v-if="isPageActive('fishing')"/>
        <app-lotto-ticket v-if="isPageActive('lotto') && AGENT_S_BASE == 'PYN'"/>
        <app-lotto-set v-if="isPageActive('lotto')"/>
        <app-lotto v-if="isPageActive('lotto')"/>
        <app-slot-game v-if="isPageActive('games/slot')"/>
      </div>
    </div>
    <app-extension-page/>
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="mx-3 px-2">
  <b-modal id="modal-news" size="md" hide-footer>
    <template #modal-title>{{ getNews[indexNews].name }}</template>
    <div class="d-block text-center">
      <div class="card-body px-3">
        <div class="row justify-content-center mb-4 text-shadow p-1">
          <div class="col-sm-12 col-md-12 m-0 text-left">
            <small>{{ getNews[indexNews].date }}</small>
          </div>
          <div class="col-sm-12 col-md-12"  v-html="getNews[indexNews].detail">
          </div>
        </div>
      </div>
    </div>
  </b-modal>
  <b-modal id="modal-news-agent" size="md" hide-footer>
    <template #modal-title>โปรโมชั่น</template>
    <div class="d-block text-center">
      <div class="card-body p-1">
        <div class="row justify-content-center mb-4 text-shadow p-1">
          <div class="col-sm-12 col-md-12"  v-html="getAgentNews.news">
          </div>
        </div>
      </div>
    </div>
  </b-modal>
    </div>
    </div>
  </div>
</template>
<script>
// import BoxVip from '@/components/box_vip'
// import MenuSidebar from '@/components/menu_sidebar'
import MenuSidebarMobile from '@/components/menu_sidebar_mobile'
import extensionPage from '@/components/extensionPage'
// import banner from '@/components/banner'
// Casino
import hotgame from '@/pages/mainPage/content/hotgame'
import casino from '@/pages/mainPage/content/casino'
import slot from '@/pages/mainPage/content/slot'
import promotions from '@/pages/mainPage/content/promotions'
import visual_sport from '@/pages/mainPage/content/visual_sport'
import sport from '@/pages/mainPage/content/sport'
import fishing from '@/pages/mainPage/content/fishing'
import lotto from '@/pages/lotteryPage/lotteryPage'
import lottoSetPage from '@/pages/lottoSetPage/lottoSetPage'
import lottoTicketPage from '@/pages/lottoTicketPage/lottoTicketPage'
import slot_game from '@/pages/mainPage/content/slot_game'

// import SwiperSlider from '@/components/SwiperSlider'
import { checkCreditTurn } from '@/services'
import { STATUS_SUCCESS } from '@/constants'
import { mapState, mapActions, mapGetters } from 'vuex'
import Header from '@/components/Header'
export default {
  name: 'Home',
  components: {
    // 'app-box-vip': BoxVip,
    'app-header': Header,
    // 'app-menu-sidebar': MenuSidebar,
    'app-menu-sidebar-mobile': MenuSidebarMobile,
    'app-extension-page': extensionPage,
    // 'app-banner': banner,
    'app-hotgame': hotgame,
    'app-casino': casino,
    'app-visual-sport': visual_sport,
    'app-slot': slot,
    'app-promotions': promotions,
    'app-sport': sport,
    'app-fishing': fishing,
    'app-lotto': lotto,
    'app-lotto-set': lottoSetPage,
    'app-lotto-ticket': lottoTicketPage,
    'app-slot-game': slot_game
    // 'app-swiper-slide': SwiperSlider
  },
  data () {
    return {
      casinoEnable: false,
      indexNews: 0,
      modalLoginShow: false,
      headerBgVariant: 'bgModalHeaderLogin',
      headerTextVariant: 'dark',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      member_username: null,
      member_password: null,
      gems: 0,
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE,
      AG_USER: process.env.VUE_APP_AG_USER,
      agentByPassKey: [
        {
          xcode: 'c857a979',
          secretkey: '123456'
        }
      ],
      // "password": "username_test",
      //   "url": "de2c2ff67015a8495fd59f23598a7388",
      //   "username": "password_test",
      //   "xcode": "ag_test"
      userObject: {
        password: '',
        url: '',
        username: '',
        xcode: ''
      }
    }
  },
  async mounted () {
    if (this.$route.path === '/register') {
      this.$bvModal.show('modal-user-register')
    }
    try {
      // console.log(this.afKey + '-------------mounted')
      // console.log(this.agKey + '-------------mounted')
      if ((this.afKey !== '' && (typeof this.afKey === 'string')) || (this.agKey !== '' && (typeof this.agKey === 'string'))) {
        this.$bvModal.hide('modal-user-login')
        this.$bvModal.show('modal-user-register')
      }
      console.log(this.$route.name)
      await this.setLoading(true)
      this.casinoEnable = process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim()
      if (this.isMember) {
        await this.getUserInfo()
        // await this.getMyAccountBanks()
        await this.getAffiliateInfo()
        // await this.getNewsList()
      }
      // await this.reqConfig(process.env.VUE_APP_AG_ID)
      // Background loading data
      await this.setLoading(false)
      // this.$swal({
      //   position: 'center',
      //   icon: 'warning',
      //   title: 'เว็ปไซต์ปิดปรับปรุงชั่วคราว',
      //   showConfirmButton: false,
      //   timer: 4000
      // })
    } catch (error) {
      this.setLoading(false)
    }
  },
  computed: {
    ...mapState({
      hasBank: state => state.bankModule.hasBank,
      isGamePermission: (state) => state.authModule.isGamePermission,
      isMember: (state) => state.authModule.isMember,
      afKey: (state) => state.registerModule.afKey,
      agKey: (state) => state.registerModule.agKey
    }),
    ...mapGetters([
      'getAffiliateLink',
      'getCurrentIncome',
      'getTotalClick',
      'getTotalIncome',
      'getDownline',
      'getTotalBet',
      'getNews',
      'getAgentNews',
      'getConfig'
    ]),
    getBalance () {
      return this.$store.getters.getBalance
    },
    getUser () {
      return this.$store.getters.getUsername
    },
    getCreditTotal () {
      return this.$store.getters.getCreditTotal
    },
    getVip () {
      return this.$store.getters.getVip
    },
    getIsVip () {
      return this.$store.getters.getIsVip
    }
  },
  methods: {
    ...mapActions(['getBalanceInfo', 'getUserInfo', 'setLoading', 'getMyAccountBanks', 'setCreditTurn', 'getAffiliateInfo', 'loginAction']),
    openLine () {
      window.open(this.getAgentNews.link)
    },
    async gotoWithdraw () {
      console.log('test')
      const resp = await checkCreditTurn()
      if (resp.status_code === STATUS_SUCCESS) {
        if (resp.data.withdraw_able === false) {
          this.setCreditTurn(resp.data.credit_turn)
          this.$router.replace('unavailablewithdraw')
        } else {
          this.$router.replace('withdraw')
        }
      }
    },
    isPageActive (pageName) {
      return (this.$route.name === pageName)
    },
    isDisabled () {
      if (this.hasBank === null) {
        return 'disabled'
      }
      return ''
    },
    pushRoute (route) {
      this.$router.replace(route)
      this.setIsActive(false)
    },
    async login () {
      if (!this.member_username && !this.member_password) {
        return false
      } else {
        this.setLoading(true)
        const isSucces = await this.loginAction({
          username: this.member_username,
          password: this.member_password
        })
        if (isSucces) {
          this.requestUsername()
          this.setLoading(false)
          this.$router.replace('hotgame')
        } else {
          this.setLoading(false)
          this.$refs.loginform.reset()
          return false
        }
      }
    }
  }
}
</script>
<style lang="less">
.bgModalHeaderLogin {
  background: linear-gradient(180deg, var(--color-1-main));
}
.bgFooter{
  background: linear-gradient(180deg, var(--color-1-nav), var(--color-2-nav));
}
.br-radius{
  border-radius: 10px;
}
</style>
